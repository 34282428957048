<template>
  <div>
    <el-radio
      v-model="_value"
      :label="e.value"
      v-for="(e, i) in $map.getter('sex')"
      :key="i"
      >{{ e.label }}</el-radio
    >
  </div>
</template>

<script>
export default {
  name: "Sex",
  props: {
    value: {
      type: String,
      default() {
        return "M";
      },
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>