<template>
  <el-select size="medium" v-model="_value" :placeholder="`请选择${name}`">
    <el-option
      :label="e.label"
      :value="e.value"
      v-for="(e, i) in $map.getter(map)"
      :key="i"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: "Selector",
  props: {
    value: [Number, String],
    map: String,
    name: String
  },
  computed: {
    _value: {
      get(){
        if(this.value){
          return this.value + "";
        }else{
          return "";
        }
      },
      set(val){
        this.$emit("input", val);
      }
    }
  },
};
</script>

<style>
</style>