<template>
  <div class="list">
    <search-box @change="research"></search-box>
    <div class="contentBox">
      <div class="contentTool">
        <el-button @click="add" type="primary" size="medium"> 添加员工 </el-button>
      </div>
      <el-table class="contentTable" :data="tableData">
        <el-table-column prop="jobNumber" label="工号">
          <template slot-scope="scope">
            {{ scope.row.jobNumber || "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="staffName" label="姓名">
          <template slot-scope="scope">
            {{ scope.row.staffName || "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="businessName" label="部门">
          <template slot-scope="scope">
            {{ scope.row.sysDeptName ? scope.row.sysDeptName.deptName : '无' }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号">
          <template slot-scope="scope">
            {{ scope.row.mobile || "无" }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="creatorName" label="岗位">
          <template slot-scope="scope">
            {{
              scope.row.sysPositionNameList
                ? scope.row.sysPositionNameList
                    .map((e) => e.positionName)
                    .join(",")
                : "无"
            }}
          </template>
        </el-table-column> -->
        <el-table-column prop="createTime" label="职级">
          <template slot-scope="scope">
            {{
              scope.row.sysRankLevelNameList
                ? scope.row.sysRankLevelNameList.map((e) => e.content).join(",")
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="entryTime" label="入职时间">
          <template slot-scope="scope">
            {{ scope.row.entryTime || "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="工作地点">
          <template slot-scope="scope">
            {{ scope.row.workPlace | address }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="操作"
          class-name="operation"
          width="150"
        >
          <template slot-scope="scope">
            <span @click="see(scope.row)" class="blue">查看</span>
            <span @click="edit(scope.row)" class="blue">编辑</span>
          </template>
        </el-table-column>
      </el-table>
      <ReconovaPagination
        v-if="tableData.length > 0"
        @paginationChange="paginationChange"
        :page-no="pageNum"
        :page-size="pageSize"
        :total="total"
      >
      </ReconovaPagination>
    </div>
  </div>
</template>

<script>
import SearchBox from "./components/searchBox";
import ReconovaPagination from "@/components/reconova/reconovaPagination";
export default {
  name: "list",
  components: {
    ReconovaPagination,
    SearchBox,
  },
  data() {
    return {
      currentId: "",
      pageNum: 1,
      pageSize: 10,
      search: { },
      tableData: [],
      total: 1,
      ids: [],
    };
  },
  methods: {
    add() {
      this.$emit("changeType", {
        type: "detail",
        id: "",
        viewStatus: "edit",
      });
    },
    see(row) {
      //查看
      this.$emit("changeType", {
        type: "detail",
        id: row.id + "",
        viewStatus: "saved",
      });
    },
    edit(row) {
      this.$emit("changeType", {
        type: "detail",
        id: row.id + "",
        viewStatus: "edit",
      });
    },
    handleSelectionChange(data) {
      this.ids = data.map((e) => e.id);
    },
    selectAble(row) {
      //table行是否可被选
      return true;
      // return row.status === 0;
    },
    paginationChange(type, val) {
      //翻页
      if (type === "pageNo") {
        this.pageNum = val;
      } else {
        this.pageNum = 1;
        this.pageSize = val;
      }
      this.getData();
    },
    research(data,isreset) {
      this.pageNum = 1;
      this.pageSize=isreset?10:this.pageSize;
      this.search.entryTimeBegin = data.date[0];
      this.search.entryTimeEnd = data.date[1];
      this.search.staffName = data.staffName;
      this.search.mobile = data.mobile;
      this.getData();
    },
    //获取列表数据
    getData() {
      this.$api.personnel.staffRosterList({...this.search,pageSize:this.pageSize,pageNum:this.pageNum}).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
  },
  mounted() {
    this.getData(this.search);
  },
};
</script>

<style lang="scss">
.list {
  .operation {
    span {
      margin-right: 8px;
    }
  }
}
</style>