<template>
  <div class="roster-detail">
      <block
        title="基本入职信息"
        class="basic-info"
        v-slot="scope"
        :status="viewStatus"
        :editable="false"
      >
        <template v-if="scope.status == 'saved'">
          <el-form>
            <el-form-item label="工号">
              {{ form.jobNumber }}
            </el-form-item>
            <el-form-item label="姓名">
              {{ form.staffName }}
            </el-form-item>
            <el-form-item label="工作部门">
              {{ form.sysDeptName ? form.sysDeptName.deptName : "无" }}
            </el-form-item>
            <el-form-item label="岗位">
              {{
                form.sysPositionNameList &&
                form.sysPositionNameList.map((e) => e.positionName).join(",")
              }}
            </el-form-item>
            <el-form-item label="职级">
              {{
                form.sysRankLevelNameList &&
                form.sysRankLevelNameList.map((e) => e.content).join(",")
              }}
            </el-form-item>
            <el-form-item label="兼职岗位">
              {{ form.ptPosition }}
            </el-form-item>
            <el-form-item label="工作地点">
              {{ form.workName }}
            </el-form-item>
            <el-form-item label="性别">
              {{ form.sex | map("sex") }}
            </el-form-item>
            <el-form-item label="手机号">
              {{ form.mobile }}
            </el-form-item>
            <el-form-item label="身份证号">
              {{ form.idCard }}
            </el-form-item>
            <el-form-item label="出生日期">
              {{ form.birthday }}
            </el-form-item>
            <el-form-item label="年龄">
              {{ form.age }}
            </el-form-item>
            <el-form-item label="民族">
              {{ form.nation }}
            </el-form-item>
            <el-form-item label="籍贯">
              {{ form.nativePlace | address }}
            </el-form-item>
            <el-form-item label="出生地">
              {{ form.birthAddress | address }}
            </el-form-item>
            <el-form-item label="户口地址">
              {{ form.residenceAddress }}
            </el-form-item>
            <el-form-item label="家庭住址">
              {{ form.homeAddress }}
            </el-form-item>
            <el-form-item label="婚姻状况">
              {{ form.maritalStatus | map("maritalStatus") }}
            </el-form-item>
            <el-form-item label="政治面貌">
              {{ form.politicalStatus | map("politicalStatus") }}
            </el-form-item>
            <el-form-item label="入党时间" v-if="form.politicalStatus == 2">
              {{ form.joinPartyTime }}
            </el-form-item>
            <el-form-item label="现有职称">
              {{ form.technicalTitle }}
            </el-form-item>
            <el-form-item label="学历">
              {{ form.education | map("education") }}
            </el-form-item>
            <el-form-item label="参加工作时间">
              {{ form.joinWorkTime }}
            </el-form-item>
            <el-form-item label="工龄">
              {{ form.workAge }}
            </el-form-item>
            <el-form-item label="内部调入时间">
              {{ form.transferTime }}
            </el-form-item>
            <el-form-item label="内部调入备注">
              {{ form.transferRemark }}
            </el-form-item>
            <el-form-item label="入司时间">
              {{ form.entryTime }}
            </el-form-item>
            <el-form-item label="司龄">
              {{
                new Date().getFullYear() -
                new Date(form.entryTime).getFullYear()
              }}
            </el-form-item>
            <el-form-item label="是否转正">
              {{ form.isRegular ? "是" : "否" }}
            </el-form-item>
            <el-form-item label="试用期结束日期">
              {{ form.endTrailTime }}
            </el-form-item>
            <el-form-item label="试用期开始日期">
              {{ form.beginTrailTime }}
            </el-form-item>
            <el-form-item label="劳动合同主体">
              {{ form.contractsPartA }}
            </el-form-item>
            <el-form-item label="合同生效日期">
              {{ form.contractsBeginTime }}
            </el-form-item>
            <el-form-item label="合同到期日期">
              {{ form.contractEndTime }}
            </el-form-item>
            <el-form-item label="合同期限">
              {{ form.contractsTerm }}
            </el-form-item>
            <el-form-item label="首次签订合同日期">
              {{ form.firstContractTime }}
            </el-form-item>
            <el-form-item label="人事档案存放单位">
              {{ form.personnelFilePlace }}
            </el-form-item>
            <el-form-item label="银行账号">
              {{ form.bankAccount }}
            </el-form-item>
            <el-form-item label="开户行">
              {{ form.bankName }}
            </el-form-item>
            <el-form-item label="邮箱">
              {{ form.emailAddress }}
            </el-form-item>
          </el-form>
        </template>
        <template v-if="scope.status == 'edit'">
          <el-form :model="form" ref="form" :rules="formRules">
            <el-form-item label="姓名" prop="staffName">
              <el-input  size="medium" v-model="form.staffName"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <r-sex v-model="form.sex"></r-sex>
            </el-form-item>
            <el-form-item label="手机号" prop="mobile">
              <el-input  size="medium" v-model="form.mobile"></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="idCard">
              <el-input size="medium" v-model="form.idCard"></el-input>
            </el-form-item>
            <el-form-item label="工作部门" prop="deptId">
              <department :checkStrictly="true" v-model="form.deptId"></department>
            </el-form-item>
            <el-form-item label="岗位" prop="positionIds">
              <position :checkStrictly="true" v-model="form.positionIds"></position>
            </el-form-item>
            <el-form-item label="职级" prop="rankLevelIds">
              <position :checkStrictly="true" v-model="form.rankLevelIds" type="grade"></position>
            </el-form-item>
            <el-form-item label="兼职岗位">
              <el-input size="medium" v-model="form.ptPosition"></el-input>
            </el-form-item>
            <el-form-item label="工作地点">
              <department :checkStrictly="true" v-model="form.workId"></department>
            </el-form-item>
            <el-form-item label="出生日期">
              <date v-model="form.birthday"></date>
            </el-form-item>
            <el-form-item label="年龄">
              <el-input size="medium" v-model="form.age"></el-input>
            </el-form-item>
            <el-form-item label="民族">
              <el-input size="medium" v-model="form.nation"></el-input>
            </el-form-item>
            <el-form-item label="籍贯">
              <place v-model="form.nativePlace" type="region"></place>
            </el-form-item>
            <el-form-item label="出生地">
              <place v-model="form.birthAddress" type="region"></place>
            </el-form-item>
            <el-form-item label="户口地址">
              <el-input size="medium" v-model="form.residenceAddress"></el-input>
            </el-form-item>
            <el-form-item label="家庭住址">
              <el-input size="medium" v-model="form.homeAddress"></el-input>
            </el-form-item>
            <el-form-item label="婚姻状况">
              <el-select size="medium"
                v-model="form.maritalStatus"
                placeholder="请选择婚姻状况"
              >
                <el-option
                  :label="e.label"
                  :value="e.value"
                  v-for="(e, i) in $map.getter('maritalStatus')"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="政治面貌">
              <el-select size="medium"
                v-model="form.politicalStatus"
                placeholder="请选择政治面貌"
              >
                <el-option
                  :label="e.label"
                  :value="e.value"
                  v-for="(e, i) in $map.getter('politicalStatus')"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="入党时间" v-if="form.politicalStatus == 2">
              <date v-model="form.joinPartyTime"></date>
            </el-form-item>
            <el-form-item label="现有职称">
              <el-input size="medium" v-model="form.technicalTitle"></el-input>
            </el-form-item>
            <el-form-item label="学历">
              <selector
                :map="'education'"
                v-model="form.education"
                :name="`学历`"
              ></selector>
            </el-form-item>
            <el-form-item label="参加工作时间">
              <date v-model="form.joinWorkTime"></date>
            </el-form-item>
            <el-form-item label="工龄">
              <el-input size="medium" v-model="form.workAge"></el-input>
            </el-form-item>
            <el-form-item label="内部调入时间">
              <date v-model="form.transferTime"></date>
            </el-form-item>
            <el-form-item label="内部调入备注">
              <el-input size="medium" v-model="form.transferRemark"></el-input>
            </el-form-item>
            <el-form-item label="入司时间">
              <date v-model="form.entryTime"></date>
            </el-form-item>
            <el-form-item label="司龄">
              <template v-if="form.entryTime">
                {{
                  new Date().getFullYear() -
                  new Date(form.entryTime).getFullYear()
                }}
              </template>
              <template v-else  class="colorGrey"> 根据入司时间计算 </template>
            </el-form-item>
            <el-form-item label="是否转正">
              <div>
                <el-radio v-model="form.isRegular" :label="true">是</el-radio>
                <el-radio v-model="form.isRegular" :label="false">否</el-radio>
              </div>
            </el-form-item>
            <el-form-item label="试用期开始日期" prop="beginTrailTime">
              <date
                v-model="form.beginTrailTime"
              ></date>
            </el-form-item>
            <el-form-item label="试用期结束日期" prop="endTrailTime">
              <date
                v-model="form.endTrailTime"
              ></date>
            </el-form-item>
            <el-form-item label="劳动合同主体" prop="contractsPartId">
              <el-select size="medium" v-model="form.contractsPartId">
                <el-option
                  v-for="itr in Contracts"
                  :key="itr.value"
                  :label="itr.text"
                  :value="itr.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="合同生效日期">
              <date v-model="form.contractsBeginTime"></date>
            </el-form-item>
            <el-form-item label="合同到期日期">
              <date v-model="form.contractEndTime"></date>
            </el-form-item>
            <el-form-item label="合同期限">
              <el-input size="medium" v-model="form.contractsTerm"></el-input>
            </el-form-item>
            <el-form-item label="首次签订合同日期">
              <date v-model="form.firstContractTime"></date>
            </el-form-item>
            <el-form-item label="人事档案存放单位">
              <el-input size="medium" v-model="form.personnelFilePlace"></el-input>
            </el-form-item>
            <el-form-item label="银行账号">
              <el-input size="medium" v-model="form.bankAccount"></el-input>
            </el-form-item>
            <el-form-item label="开户行">
              <el-input size="medium" v-model="form.bankName"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="emailAddress">
              <el-input size="medium" v-model="form.emailAddress"></el-input>
            </el-form-item>
          </el-form>
        </template>
      </block>

      <block
        title="学习经历"
        v-slot="scope"
        class="column positionRelative"
        :status="viewStatus"
        :editable="false"
      >
        <template v-if="scope.status == 'saved'">
          <el-form v-for="(e, i) in form.learnExperience" :key="i">
            <p>学习经历{{ i + 1 }}</p>
            <el-form-item label="起始日期">
              {{ e.beginTime }}
            </el-form-item>
            <el-form-item label="结束日期">
              {{ e.endTime }}
            </el-form-item>
            <el-form-item label="学位">
              {{ e.academicDegree | map("academicDegree") }}
            </el-form-item>
            <el-form-item label="学历">
              {{ e.education | map("education") }}
            </el-form-item>
            <el-form-item label="学习方式">
              {{ e.learnMethod | map("learnMethod") }}
            </el-form-item>
            <el-form-item label="所在院校">
              {{ e.schoolName }}
            </el-form-item>
            <el-form-item label="专业">
              {{ e.specialty }}
            </el-form-item>
            <el-form-item label="证明人">
              {{ e.witness }}
            </el-form-item>
          </el-form>
        </template>
        <template v-if="scope.status == 'edit'">
          <el-form v-for="(e, i) in form.learnExperience" :key="i">
            <p>学习经历{{ i + 1 }}</p>
            <el-form-item label="起始日期">
              <date v-model="e.beginTime"></date>
            </el-form-item>
            <el-form-item label="结束日期">
              <date v-model="e.endTime"></date>
            </el-form-item>
            <el-form-item label="学位">
              <selector
                v-model="e.academicDegree"
                :name="`学位`"
                :map="'academicDegree'"
              ></selector>
            </el-form-item>
            <el-form-item label="学历">
              <selector
                v-model="e.education"
                :name="`学历`"
                :map="'education'"
              ></selector>
            </el-form-item>
            <el-form-item label="学习方式">
              <selector
                v-model="e.learnMethod"
                :name="`学习方式`"
                :map="'learnMethod'"
              ></selector>
            </el-form-item>
            <el-form-item label="所在院校">
              <el-input size="medium" v-model="e.schoolName"></el-input>
            </el-form-item>
            <el-form-item label="专业">
              <el-input size="medium" v-model="e.specialty"></el-input>
            </el-form-item>
            <el-form-item label="证明人">
              <el-input size="medium" v-model="e.witness"></el-input>
            </el-form-item>
            <el-button
              @click="remove('learnExperience', i)"
              class="remove"
              type="danger"
              >删除</el-button
            >
          </el-form>
          <el-button @click="add('learnExperience')" type="primary"
            >新增</el-button
          >
        </template>
      </block>
      <block
        title="工作经历"
        v-slot="scope"
        class="column"
        :status="viewStatus"
        :editable="false"
      >
        <template v-if="scope.status == 'saved'">
          <el-form v-for="(e, i) in form.workExperience" :key="i">
            <p>工作经历{{ i + 1 }}</p>
            <el-form-item label="起始日期">
              {{ e.beginTime }}
            </el-form-item>
            <el-form-item label="结束日期">
              {{ e.endTime }}
            </el-form-item>
            <el-form-item label="工作单位">
              {{ e.companyName }}
            </el-form-item>
            <el-form-item label="工作部门">
              {{ e.deptName }}
            </el-form-item>
            <el-form-item label="职务">
              {{ e.position }}
            </el-form-item>
            <el-form-item label="工作性质">
              {{ e.workNature | map("workNature") }}
            </el-form-item>
          </el-form>
        </template>
        <template v-if="scope.status == 'edit'">
          <el-form v-for="(e, i) in form.workExperience" :key="i">
            <p>工作经历{{ i + 1 }}</p>
            <el-form-item label="开始年月">
              <date v-model="e.beginTime"></date>
            </el-form-item>
            <el-form-item label="结束日期">
              <date v-model="e.endTime"></date>
            </el-form-item>
            <el-form-item label="工作单位">
              <el-input size="medium"  v-model="e.companyName"></el-input>
            </el-form-item>
            <el-form-item label="工作部门">
              <el-input size="medium"  v-model="e.deptName"></el-input>
            </el-form-item>
            <el-form-item label="职务">
              <el-input size="medium"  v-model="e.position"></el-input>
            </el-form-item>
            <el-form-item label="工作性质">
              <el-select size="medium"  v-model="e.workNature" placeholder="请选择工作性质">
                <el-option
                  :label="ele.label"
                  :value="ele.value"
                  v-for="(ele, index) in $map.getter('workNature')"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-button
              @click="remove('workExperience', i)"
              class="remove"
              type="danger"
              >删除</el-button
            >
          </el-form>
          <el-button @click="add('workExperience')" type="primary"
            >新增</el-button
          >
        </template>
      </block>
      <div style="height: 76px"></div>
      <div class="footer">
        <el-button @click="back" size="medium">返回</el-button>
        <el-button
          @click="submit"
          type="primary"
          v-if="viewStatus == 'edit'"
          v-throttle
          size="medium"
          >保存</el-button
        >
      </div>
  </div>
</template>

<script>
import Block from "@/components/block.vue";
import Department from "@/components/department.vue";
import Position from "@/components/position.vue";
import Place from "@/components/place.vue";
import RSex from "@/components/r-sex.vue";
import Date from "@/components/date.vue";
import Selector from "@/components/selector.vue";
import { validateIDcard } from "@/utils/validator";

export default {
  name: "detail",
  components: {
    Block,
    Department,
    Position,
    Place,
    RSex,
    Date,
    Selector,
  },
  data() {
    return {
      Contracts: [], // 劳动合同主体数据list
      activeName: "basicInfo",
      form: {
        age: "",
        bankAccount: "",
        bankName: "",
        birthAddress: {
          cityCode: 0,
          cityName: "",
          code: 0,
          name: "",
          provinceCode: 0,
          provinceName: "",
        },
        birthday: "",
        contractEndTime: "",
        contractsBeginTime: "",
        contractsPartA: "",
        contractsTerm: "",
        deptId: "",
        education: "",
        emailAddress: "",
        endTrailTime: "",
        entryTime: "",
        firstContractTime: "",
        homeAddress: "",
        idCard: "",
        isRegular: true,
        joinPartyTime: "",
        joinWorkTime: "",
        learnExperience: [
          {
            academicDegree: "",
            beginTime: "",
            education: "",
            endTime: "",
            learnMethod: "",
            schoolName: "",
            specialty: "",
            witness: "",
          },
        ],
        maritalStatus: "",
        mobile: "",
        nation: "",
        nativePlace: {
          cityCode: 0,
          cityName: "",
          code: 0,
          name: "",
          provinceCode: 0,
          provinceName: "",
        },
        personnelFilePlace: "",
        politicalStatus: "",
        positionIds: [],
        ptPositionIds: [],
        rankLevelIds: [],
        residenceAddress: "",
        sex: "M",
        staffName: "",
        technicalTitle: "",
        transferRemark: "",
        transferTime: "",
        workAge: "",
        workExperience: [
          {
            beginTime: "",
            companyName: "",
            deptName: "",
            endTime: "",
            position: "",
            workNature: "",
          },
        ],
        workId: 0,
        workPlace: {
          cityCode: 0,
          cityName: "",
          code: 0,
          name: "",
          provinceCode: 0,
          provinceName: "",
        },
      },
      formRules: {
        staffName: [this.$formValidator.empty("姓名")],
        sex: [this.$formValidator.empty("性别")],
        mobile: [this.$formValidator.empty("手机号")],
        idCard: [
          this.$formValidator.empty("身份证号"),
          { validator: validateIDcard },
        ],
      },
    };
  },
  props: {
    id: {},
    viewStatus: {
      type: String,
      default() {
        return "edit";
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    getContracts() {
      this.$api.personnel
        .getContractsList({ pageNum: 1, pageSize: 10000 })
        .then((response) => {
          this.Contracts = response.data;
        });
    },
    init() {
      this.getContracts();
      if (this.id !== "") {
        this.$api.personnel
          .staffRosterGet({
            id: this.id,
          })
          .then((res) => {
            this.form = res.data;
            ["education", "maritalStatus", "politicalStatus"].forEach(
              (e) => (this.form[e] += "")
            );
          });
      }
    },
    back() {
      this.$emit("changeType", {
        type: "list",
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let func = this.id
            ? this.$api.personnel.staffRosterUpdate
            : this.$api.personnel.staffRosterInsert;
          let contract = this.Contracts.filter(
            (itr) => itr.value == this.form.contractsPartId
          );
          this.form.contractsPartA = contract.length ? contract[0].text : "";
          func(this.form).then((res) => {
            this.$message.success(res.msg);
            if (res.code == 200) {
              setTimeout(() => {
                this.back();
              }, 1000);
            }
          });
        } else {
          this.$message.warning("请填写必填信息");
        }
      });
    },
    add(name) {
      const MAP = {
        workExperience: {
          beginTime: "",
          companyName: "",
          deptName: "",
          endTime: "",
          position: "",
          workNature: "",
        },
        learnExperience: {
          academicDegree: 0,
          beginTime: "",
          education: 0,
          endTime: "",
          learnMethod: 0,
          schoolName: "",
          specialty: "",
          witness: "",
        },
      };
      !this[name] && (this[name] = []);
      this.form[name].push(MAP[name]);
    },
    remove(name, index) {
      this.form[name].splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content .el-input,
.el-form-item__content .el-select .el-input{
  width: 260px !important;
}
.roster-detail {
  padding: 40px;
  background-color: white;
  .basic-info {
    .el-form {
      display: flex;
      flex-wrap: wrap;
      .el-form-item {
        width: 50%;
        flex-shrink: 0;
        display: flex;
        .el-form-item__label {
          width: 150px;
        }
      }
    }
  }
  .column {
    .el-form {
      display: flex;
      flex-wrap: wrap;
      .el-form-item {
        width: 50%;
        flex-shrink: 0;
        display: flex;
        .el-form-item__label {
          width: 150px;
        }
      }
      & > p {
        width: 100%;
        margin: 0 0 10px 0;
        font-weight: 600;
      }
      .remove {
        position: absolute;
        right: 40px;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    background-color: white;
    left: 262px;
    display: flex;
    width: calc(100% - 282px);
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    z-index: 10;
  }
}
</style>

<style lang="scss">
.roster-detail {
  .el-form-item__content {
    display: flex;
  }
  .el-form-item {
    .el-form-item__label {
      width: 150px;
    }
  }
}
</style>