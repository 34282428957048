<template>
  <el-cascader size="medium" :options="data" v-model="_value"> </el-cascader>
</template>

<script>
import {
  provinceAndCityData,
  regionData,
  CodeToText,
} from "element-china-area-data";
export default {
  name: "Place",
  data() {
    return {
      data: (() => {
        if (this.$attrs.type == "region") {
          return regionData;
        } else {
          return provinceAndCityData;
        }
      })(),
    };
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          cityCode: 0,
          cityName: "",
          code: 0,
          name: "",
          provinceCode: 0,
          provinceName: "",
        };
      },
    },
  },
  computed: {
    _value: {
      get() {
        let res = [this.value.provinceCode + "", this.value.cityCode + ""];
        if (this.$attrs.type == "region") {
          res.push(this.value.code + "");
        }
        return res;
      },
      set(val) {
        this.$emit("input", {
          cityCode: val[1],
          cityName: CodeToText[val[1]],
          code: val[2],
          name: CodeToText[val[2]],
          provinceCode: val[0],
          provinceName: CodeToText[val[0]],
        });
      },
    },
  },
};
</script>

<style>
</style>